/* layout */

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-template-rows: minmax(300px, 1fr);
  grid-auto-rows: 1fr;
  grid-gap: 16px;
  grid-auto-flow: dense;
  margin: 2rem;
}

.grid-template-columns {
  grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
}

.grid-gap {
  grid-gap: 2.5rem;
}

/* styling */

.card--expanded {
  background-color: #6c6c6c;
  grid-column: span 2;
  grid-row: span 2;
  padding: 0rem;
}

.card {
  background-color: #222 !important;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0rem;
}

.card > div {
  background-color: #222;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.card__avatar {
  height: 6rem;
  width: 6rem;
  border-radius: 100%;
  background-color: hsla(0, 0%, 0%, 0.2);
  margin-bottom: 1rem;
}

.card__title {
  /* height: 1rem;
  width: 7rem; */
  /* background-color: hsla(0, 0%, 0%, 0.6); */
  /* margin-bottom: 1rem; */
  /* margin-top: -360px; */
}

.card__description {
  height: 2rem;
  width: 8rem;
  /* background-color: hsla(0, 0%, 0%, 0.2); */
}

/* .card--1 {
  background-color: #366f6e;
  background-color: #fafafa;
}

.card--2 {
  background-color: #79ac91;
  background-color: #fafafa;
}

.card--3 {
  background-color: #e2b060;
}

.card--4 {
  background-color: #e86448;
}

.card--5 {
  background-color: #dc5263;
} */

body {
  background-color: #222 !important;
  /* color: lightgray; */
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Helvetica Neue", Arial, sans-serif;
  font-size: 1.1rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.pt-4 {
  margin-top: 1rem;
}

.p-4 {
  padding: 1rem;
}

button {
  padding: 0.75rem;
  margin-right: 0.75rem;
  background-color: #191919;
  color: lightgray;
  border: 1px solid lightgray;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: hsla(0, 0%, 83%, 0.05);
}
button:focus {
  box-shadow: 0 0 0 3px #7396e4;
  outline: none;
}

a {
  font-weight: bold;
  color: #7396e4;
}

code {
  color: #7396e4;
}
